export const LANG_MODE = 'ja';

export const LANG = {

	ADDR01: '市区町村'
	, ADDR02: '番地'
	, ADDR03: '建物名・部屋番号'

	, YEAR: '年'
	, MONTH: '月'
	, DAY: '日'

	, YEN: '円'
	, SUPPORTER: 'サポーター'
	, STRING: '文字'
	, WITHIN: '以内'
	, COUNT: '件'
	, OTHER: '他'
	, YEN_2: '¥'

	, MESSAGE_GOOD_MORNING: 'おはようございます'
	, MESSAGE_HELLO: 'こんにちは'
	, MESSAGE_GOOD_EVENING: 'こんばんは'
	, MESSAGE_TPL_1: '本日も充実した時間をお過ごしいただけますようサポートさせてください。'

	, ATTR_EMAIL: 'メールアドレス'
	, ATTR_TOKEN: '確認コード'
	, ATTR_NICKNAME: 'ニックネーム'
	, ATTR_NAME: '名前'
	, ATTR_CONTACT_TITLE: 'お問い合わせの種類'
	, ATTR_CONTACT_BODY: 'お問い合わせ内容'
	, ATTR_PROFILE_TXT: '自己紹介文'
	, ATTR_PASSWORD: 'パスワード'
	, ATTR_PASSCONFIRM: 'パスワード(確認用)'
	, ATTR_PASSWORD_NOW: '現在のパスワード'
	, ATTR_PASSWORD_AFTER: '変更後のパスワード'
	, ATTR_PASSCONFIRM_AFTER: '変更後のパスワード(確認用)'
	, ATTR_BIRTHDAY: '生年月日'
	, ATTR_GENDER: '性別'
	, ATTR_SUBS_EXPIRE: '契約期限'
	, ATTR_CARD_NO: 'カード番号'
	, ATTR_CARD_EXPIRE: '有効期限'
	, ATTR_CARD_CVC: 'セキュリティコード'

	, ATTR_CARD_INFO: 'クレジットカード'

	, ATTR_PLATFORM_TYPE: '主に使用している配信サービス'

	, ATTR_URL: 'URL'
	, ATTR_DESCRIPTION: '紹介文'
	, ATTR_TITLE: 'タイトル'
	, ATTR_SELECT_ITEM: '選択肢'

	, ATTR_DATE_FROM: '日付(From)'
	, ATTR_DATE_TO: '日付(To)'

	, ATTR_PAYMENT: 'お支払い方法'
	, ATTR_SENDER: '投稿者'

	, ATTR_FANS_NAME: 'サポーターの呼称'
	, TEXT_FANS_NAME: 'サポーターに個性ある"愛称"をつけて絆を深めましょう！'
	, TEXT_FANS_NAME_INTRO: '有料登録してくれているファン「サポーター」だけの'
	, TEXT_FANS_NAME_INTRO_SUB: '特別な呼び名をつけられます'
	, TEXT_FANS_NAME_INTRO_INFO: '設定した呼称は各有料コンテンツ上で表示されます'
	, ATTR_SUBS_TXT: '有料登録を促すメッセージ'
	, NOTICE_CRE_SUBS_TXT: '有料登録していないファンにアピールしましょう！'

	, MENU_CRE_QUESTION: 'サポーターへの<br/>質問'
	, PAGE_TITLE_REQEST: 'サポーターへの質問'

	, MENU_CRE_WISHLIST: '欲しいもの'
	, PAGE_TITLE_WISHLIST: '欲しいものリスト'

	, MENU_CRE_PROFILE: 'プロフィール'

	, MENU_CRE_LINKS: 'リンクまとめ'

	, MENU_CRE_SETTING: '設定'
	, PAGE_TITLE_SETTINGS: '設定'

	, MENU_CRE_PREMIUM_CHAT: 'チャット入室'

	, MENU_CRE_CHAT_RESERVE: '投稿予約'
	, PAGE_TITLE_CHAT_RESERVS: 'チャット投稿予約'

	, MENU_CRE_PREVIEW: 'プレビュー'
	, PAGE_TITLE_URLS: 'プレビュー'


	, MENU_PREMIUM_PRESENT: 'プレゼントの相談'
	, MENU_PREMIUM_CHAT: 'コミュニティチャット'
	, MENU_PREMIUM_QUESTION: 'からの質問'

	, PAGE_TITLE_PREMIUM_CHAT: 'コミュニティチャット'
	, PAGE_TITLE_CHAT_BLOCK: 'チャットブロック設定'


	, PAGE_TITLE_PLAN_ENTRY: 'サポーター登録'
	, PAGE_TITLE_PLAN_CHANGE: 'サポートプランの変更'
	, PAGE_TITLE_PLAN_RESTART: 'サポートの再開'
	, PAGE_TITLE_PAY_CHANGE: 'お支払い方法'
	, PAGE_TITLE_PROF_CHANGE: 'プロフィール情報の変更'
	, PAGE_TITLE_QUIT_ALL: 'アカウントの削除・退会'
	, PAGE_TITLE_SETTING_LOGIN: 'ログイン情報の変更'
	, PAGE_TITLE_SETTING_SUPPORTER: 'サポーター関連設定'
	, PAGE_TITLE_PLATFORM_URL: '配信サービスURL設定'



	, PAGE_TITLE_REQ_RESULT: '回答集計結果'
	, PAGE_TITLE_SUPPORTER: 'サポーター一覧・管理'

	, TEXT_PREVIEW_URLS: 'ファンから見た自分のページを確認できます'

	, CONFIRM_DELETE_TITLE: '削除します'
	, CONFIRM_DELETE_ACCOUNT: '退会します'
	, CONFIRM_BLOCK: 'ブロック設定を行います'
	, CONFIRM_UNBLOCK: 'ブロック解除を行います'
	, CONFIRM_STOP_PLAN_TITLE: 'ご利用プランの自動更新を停止します'
	, CONFIRM_STOP_PLAN_BODY: 'ご利用期限までは引き続きご利用になれます。'
	, CONFIRM_RESTART_PLAN_TITLE: 'サポートの再開を行います'
	, CONFIRM_RESTART_PLAN_BODY: '再開を行うと、ご利用期限までは再停止が行えませんのでご注意ください。'
	, CONFIRM_USER_DELETE_BODY: 'ユーザーはログインができなくなります。 もしもの場合、システム管理者による復元は可能です。'
	, CONFIRM_CHAT_BLOCK: 'このサポーターが過去に投稿したメッセージは非表示になります。'
	, CONFIRM_CHAT_COMEBACK: 'このサポーターが過去に投稿したメッセージは再表示されます。'

	// , CONFIRM_STOP_CANCEL_PLAN_TITLE: 'ご利用プランの自動更新を再開します'
	, CONFIRM_STOP_CANCEL_PLAN_TITLE: 'ご利用プランの自動更新を再開します。'
	, CONFIRM_SEND_CONTACT: 'お問い合わせを送信します'

	, CONFIRM_CHANGE_PLAN_STATUS_TITLE: '本当にステータスを変更してもいいですか？'
	, CONFIRM_CHANGE_PLAN_STATUS_BODY: 'ステータス変更後も次回更新までは現在の契約が維持されます。'

	, COMPLETE_PW_RESET_MAIL: 'パスワード再設定に関するメールを送信しました'
	, COMPLETE_PW_RESET: 'パスワードが再設定されました'
	, COMPLETE_AUTH_RESET: 'メールで認証コードを送信しました'
	, COMPLETE_REGIST_ACCOUNT_MEM: 'アカウントが登録されました。引き続きご利用プランの選択を行なってください。'
	, COMPLETE_REGIST_ACCOUNT_CRE: 'アカウントが登録されました。引き続き情報の登録を行なってください。'
	, COMPLETE_REGIST_ACCOUNT: 'アカウントが登録されました。'

	, COMPLETE_PROFILE_UPDATE: 'プロフィールが更新されました'
	, COMPLETE_PLAN_UPDATE: 'ご利用プランが更新されました'
	, COMPLETE_PAYMENT_UPDATE: 'お支払い方法が更新されました'
	, COMPLETE_PLAN_STOP: 'ご利用プランの更新を停止しました'
	, COMPLETE_PLAN_STOP_CANCEL: 'ご利用プランの自動更新を再開しました'
	, COMPLETE_LINKS_UPDATE: 'リンク設定が更新されました'
	, COMPLETE_REQUEST_UPDATE: 'サポーターへの質問が更新されました'
	, COMPLETE_REQUEST_CREATE: 'サポーターへの質問が作成されました'
	, COMPLETE_AUTH_246: '246アカウントと連携しました'
	, COMPLETE_URL_UPDATE: 'URLが更新されました'
	, COMPLETE_SEND_CONTACT: 'お問い合せを送信しました'
	, COMPLETE_MESSAGE_UPDATE: 'メッセージ予約が更新されました'
	, COMPLETE_MESSAGE_CREATE: 'メッセージが予約されました'
	, COMPLETE_MESSAGE_CANCEL: 'メッセージの予約がキャンセルされました'
	, COMPLETE_SETTING_UPDATE: '設定が更新されました'
	, COMPLETE_WISHLIST_CREATE: '欲しいものが登録されました'
	, COMPLETE_WISHLIST_UPDATE: '欲しいリスト更新されました'

	, ENTER_ONLY_FOR_UPDATE: '更新の場合のみ入力'
	, INPUT_REMEMBER_ME: 'ログイン状態を保持する'
	, CONNECTED_SERVICE: '連携済み'


	, LABEL_PASSWORD_RESET: 'パスワード忘れちゃった？'
	, LABEL_CREATE_USER: '新たに会員登録される方はこちら'
	, LABEL_TO_LOGIN: 'すでに登録済みの方はこちら'
	, LABEL_PUBLISH: '公開'
	, LABEL_PUBLISH_TERM: '公開期間'
	, LABEL_PUBLIC: '公開中'
	, LABEL_PRIVATE: '非公開'
	, LABEL_ANSWER_NUM: '回答数'
	, LABEL_SEARCH: '検索'
	, LABEL_AUTH_246: '246アカウントと連携する'
	, LABEL_REGISTED_CARD: '登録中のクレジットカード'
	, LABEL_CHANGE_CARD: 'クレジットカードを変更する'
	, LABEL_RESEND: 'メールを再送する'
	, LABEL_BLOCK_SEARCH: 'ユーザー名で検索'

	, LABEL_CREATOR_ENTRY: 'クリエイター登録'

	, LABEL_STOPPED: '停止済のサポート'
	, LABEL_GOING: '継続中のサポート'

	, LABEL_SUBS_EXPIRES: '\nまで'
	, LABEL_SUBS_EXPIRES_STATUS: '\nまでサポート中'
	, LABEL_SUBS_FRONT_KAK: '【'
	, LABEL_SUBS_END_KAK: '】'
	, LABEL_SUBS_PLAN: 'ご利用プラン'
	, LABEL_LOGIN: 'ログイン'
	, LABEL_LOGOUT: 'ログアウト'
	, LABEL_FAQ: 'よくある質問'

	, LABEL_ACCOUNT: 'アカウント'
	, LABEL_PROFILE: 'プロフィール'
	, LABEL_LINK: 'リンク'

	, LABEL_PARIVACY_POLICY: 'プライバシーポリシー'
	, LABEL_TERMS_OF_SERVICE: '利用規約'
	, LABEL_SPECIFIC_LAW: '特定商取引法に基づく表記'
	, LABEL_PERMISSION: '利用規約・プライバシーポリシー及び、特定商取引法に基づく表記に同意する'
	, LABEL_CHAT_GUIDELINES: 'コミュニティチャットガイドライン'
	, LABEL_GIFT_GUIDELINES: '欲しいものリスト作成の注意事項'
	, LABEL_GIFT_MEM_GUIDELINES: 'プレゼントの注意事項'
	, LABEL_CHAT_RECIPIRNTS_SEARCH: 'ユーザー名で検索'
	, LABEL_ALL_RECIPIRNTS: '参加者一覧'
	, LABEL_ADD_LINK: 'リンクの登録'
	, LABEL_RECOMMEND: 'おすすめ'
	, LABEL_PREMIUM_LINKS: 'のリンク集'
	, AGREE_BTN_CHAT_GUIDELINES: '同意する'

	, LINK_BECOME_SUPPORTER: 'サポーターになって応援しよう ▶︎'
	, LINK_ALREADY_SUPPORTED: '既にサポーターになっている方はこちら ▶︎'

	, LABEL_MR: 'さん'
	, LABEL_MR_R: '様'
	, LABEL_JEE: '爺'
	, LABEL_SELF: '自分のアカウント'

	, LABEL_MESSAGE: 'メッセージ'
	, LABEL_IMAGE: '画像'
	, LABEL_ITEM_URL: '商品のURL'
	, LABEL_ITEM_PRICE: '商品の価格'
	, LABEL_AMENDMENT: '補足事項'

	, LABEL_PREVIEW_SUPPORTER_PAGE: 'サポーターページのプレビュー'
	, LABEL_GOTO_LOGIN_PAGE: 'ログインページへ'
	, LABEL_OTHER_SNS: 'その他のSNS等'

	, FOOT_LINK_M_ACC: 'アカウント情報・管理'
	, FOOT_LINK_M_PAY: 'お支払い方法'
	, FOOT_LINK_M_HELP: 'ヘルプ・お問い合わせ'
	, FOOT_LINK_M_LOGOUT: 'ログアウト'

	, CREATE_CREATOR_INFO: 'クリエイター情報の登録'
	, CREATE_USER_INFO: 'ユーザー情報の登録'



	, BTN_NEXT: '次へ進む'
	, BTN_EDIT: '変更する'
	, BTN_REGIST: '登録する'
	, BTN_ENTRY: '申し込む'
	, BTN_SAVE: '保存する'
	, BTN_SEND: '送信する'
	, BTN_AUTH: '認証する'
	, BTN_DOWNLOAD: 'ダウンロード'
	, BTN_SEND_CANCEL: '送信取消'
	, BTN_ENTER_ROOM: 'チャットに入室する'
	, BTN_COMPLETE: '完了する'
	, BTN_BACK: '戻る'
	, BTN_ADD: '\nリンクの追加'
	, BTN_ADD_MESSAGE: 'メッセージの追加'
	, BTN_SEND_AUTH_MAIL: '仮登録メールを送信する'
	, BTN_CLOSE: '閉じる'
	, BTN_ACCOUNT_STOP: 'アカウントの削除・退会'
	, BTN_LOGIN: 'ログイン'
	, BTN_AUTO_UPDATE: '更新再開'
	, BTN_STOP_AUTO_UPDATE: '自動更新を停止する'
	, BTN_RESTART: '再開する'
	, BTN_TO_CREATOR_PAGE: 'クリエイターページへ'
	, BTN_HOME: 'ホーム'
	, BTN_NEW_CREDIT_CARD: '新しいカードに変更する'
	, BTN_EDIT_PROFILE: 'プロフィールの変更'
	, BTN_ENTRY_PREMIUM: 'プレミアム登録'
	, BTN_EDIT_BG_IMG: 'ヘッダー画像の変更'
	, BTN_EDIT_PROF_IMG: 'プロフィール画像の変更'
	, BTN_EDIT_CARD: 'カード情報の変更'
	, BTN_REGIST_SUPPORTER: 'サポーター登録する'
	, BTN_PREMIUM_PAGE: 'サポーター限定ページへ'
	, BTN_REQ_ADD: '質問の追加'
	, BTN_WISHLIST_ADD: '欲しいものの登録'

	, BTN_DIALOG_OK: '確認'
	, BTN_DIALOG_CANCEL: 'キャンセル'

	, BTN_REQ_EDIT: '編集'
	, BTN_REQ_DELETE: '削除'
	, BTN_REQ_RESULT: '結果を見る'

	, BTN_PAGE_PREVIEW: 'ページ確認'
	, BTN_REQ_MES_RESERV: '作成する'
	, BTN_BLOCK: 'ブロック'
	, BTN_UNBLOCK: '解除'
	, BTN_CHAT_BLOCK: '入室ブロック設定'

	, PLACEHOLDER_MESSAGE_SEARCH: 'メッセージの検索'
	, TITLE_MESSAGE_SEARCH: 'メッセージの検索結果'
	, MESSAGE_NO_FOUND: '検索結果はありません'
	, MESSAGE_NO_FOUND_HINT: 'キーワードをご確認ください。キーワード数を減らしてお試しください。'
	, TITLE_FILE_PREVIEW: 'アップロードファイルの確認'
	, BTN_FILE_UPLOAD: '送信'
	, BTN_FILE_CANCEL: 'キャンセル'


	, REQS_EDITOR_ADD: '保存'
	, REQS_EDITOR_TITLE: '質問の編集'
	, REQS_EDITOR_LABEL_TITLE: '質問のタイトル'
	, REQS_EDITOR_LABEL_FROM: '集計を開始する日付'
	, REQS_EDITOR_LABEL_TO: '集計を終了する日付'
	, REQS_EDITOR_ANSWER_TITLE: '回答内容'
	, REQS_EDITOR_ANSWER_TEXT: '表示順は並べ替え可能'
	, REQS_EDITOR_ANSWER_BTN: '追加'

	, RSV_EDITOR_TITLE: '予約内容の編集'
	, RSV_EDITOR_SENDER_SIDENOTE: '執事/自分のどちらから発言するか選択してください'
	, LABEL_MESSAGE_SCHEDULE: '投稿日時'
	, LABEL_MESSAGE_CONTENT: '投稿内容'
	, BTN_ADD_IMAGE: '画像を選択'
	, BTN_ADD_POST_CONTENT: 'メッセージの追加'
	, BTN_CONFIRM_RSV: '保存'

	, WL_EITOR_TITLE: '欲しいものの編集'
	, WL_EDITOR_STATUS_LABEL: '公開ステータス'

	, ERR_INVALID_OPERATION: '不正な画面遷移です'
	, ERR_UNEXPECTED: '予期せぬエラーが発生しました。'
	, ERR_UNEXPECTED_BODY: 'お手数ですが、しばらくしてから再度実行してください。'
	, ERR_SECURITY: '時間経過や不正操作によるセキュリティエラーが発生しました。'
	, ERR_SECURITY_BODY: '画面を再読み込みするか、ブラウザを再起動してから実行してください。'
	, ERR_BROWSER: 'セキュリティ担保のため、申請時と同一のブラウザで操作を行なってください'
	, ERR_REGISTED: '既に登録されています'
	, ERR_NORMAL: 'エラーが発生しました'
	, ERR_NOT_ALLOWED: 'このページにはアクセスできません'
	, ERR_PAGE_NOTFOUND: 'ページが存在しません'

	, ERR_LINK: '必ず下記のリンクから画面を再読み込み行なってください。'
	, ERR_RELOGIN: '下記からログインを行なってください。'
	, ERR_SECURITY_ATTENTION: 'それでも解消しない場合は、一度ご利用のブラウザを再起動してから実行してください。'

	, ERR_AUTH_246: '246アカウントとの連携に失敗しました'
	, ERR_CREDIT_CARD: 'カード情報をご確認ください。'
	, ERR_FILE_EXPIRED: '※ファイルを表示できません'
	, ERR_NOT_GET: '※取得できませんでした'
	, ERR_BROWSER_MAX_FILE_SIZE: 'このブラウザでアップロードできるファイルサイズを超えています。'

	, NOTICE_CREDIT_CARD_EXPIRES: 'カードの有効期限が切れています。別のカードに変更してください。'
	, NOTICE_EDIT_PASSWORD: '変更する場合のみ入力してください。'
	, NOTICE_CHANGE_PLAN: '次回更新時に変更されたプランが適用されます。'

	, NOTICE_CHECK_ACCOUNT_STOP_1: '退会後は全てのご利用プランがご利用いただけなくなります。'
	, NOTICE_CHECK_ACCOUNT_STOP_2: 'ご利用プランの期限が残っている場合もご返金はいたしかねます。'
	, NOTICE_CHECK_CREATOR_STOP_1: '退会後は全ての機能がご利用いただけなくなります。'
	, NOTICE_CHECK_CREATOR_STOP_2: 'アップロードされたファイルやチャット履歴等のデータは退会後に速やかに削除されます。'

	, NOTICE_NOT_CREATOR_STOP: '有料サポーターの契約が残っているため、退会を行えません。\n有料サポーターに、ご利用プランの更新ステータスを「次回更新なし」に変更してもらってください。'

	, NOTICE_ACCOUNT_STOP: '上記事項にあらかじめご了承の上、「退会する」を押してください。'

	, NOTICE_SNS_LINK: 'URLの登録がある場合のみリンク集に表示されます'

	, NOTICE_MULTI_SELECT: '複数選択可'
	, NOTICE_CRE_PROF_IMG: 'ファンページやリンク集で使用されます'
	, NOTICE_CRE_PROF_TXT: 'ファンが訪問した際に表示されます'
	, NOTICE_CRE_BIRTHDAY: '西暦は公開されません'
	, NOTICE_SORT_LINK: '表示したい順に並び替え可能'
	, NOTICE_LINK_REGULATION: '媒体の規定により非表示になる場合があります'
	, NOTICE_ENTRY_PROFILE: '登録した情報は後から変更できます'
	, NOTICE_NO_HYPHEN: 'ハイフンなし'
	, NOTICE_MMYY: '月/年'
	, NOTICE_NOT_REGIST_PLATFORM: '使用している配信サービスの設定が行われていません。\n先にプロフィールの設定を完了してください。'
	, NOTICE_NOT_REGIST_PLATFORM_URLS: '欲しいものをプレゼントしてもらった時のお届け先を確認するため、\n事前に「使用している配信サービスのアカウントページ」を登録してください。'
	, NOTICE_NOT_REGIST_BTN: 'プロフィール設定へ'
	, NOTICE_NOT_REGIST_PLURLS_BTN: '配信サービスのURL設定へ'
	, NOTICE_PASSWORD_EDIT: 'パスワードの変更がある場合のみ入力してください'
	, NOTICE_GIFT_URL: 'Amazon等のECモールではなく、なるべく公式サイトの商品ページを登録するようにしましょう。'
	, NOTICE_GIFT_PRICE: 'ギフトの予算条件にマッチさせるために必ず入力してください。不明な場合はおおよその金額を入力してください。'

	, PH_NICKNAME: 'ヘイジー'
	, PH_PASSWORD: '半角英数8文字以上'
	, PH_PASSWORD_CHK: 'パスワードの再入力'
	, PH_LINK_DESCRIPTION: 'リンクの説明文 ※30文字以内'
	, PH_CHAT_MESSAGE: 'メッセージを入力してください'
	, PH_SNS_MYURL: 'プロフィールページのURL'
	, PH_FANS_NAME: '例）モノノフ、アラシック'
	, PH_SUBS_TXT: 'いつも応援してくれてありがとう！これからもっと深い繋がりを持ちたいと思って、有料ファンの方に向けた特別なコミュニティを作りました！チェックしてみてね！'
	, PH_BUY_AMENDMENT: '例）・カラーは白を希望\n・サイズはSを希望 etc...'

	, TEXT_USER_THANKS: '退会手続きが無事に完了いたしました。'
	, TEXT_SEE_YOU_AGAIN: 'これまでのご愛顧、\n誠にありがとうございました。'
	, BTN_SEE_YOU: '閉じる'

	, TEXT_CRE_ENTRY_TITLE_1: 'あなたの執事が'
	, TEXT_CRE_ENTRY_TITLE_2: 'ライブ活動をサポートします'

	, TEXT_TOKEN_CONFIRM_1: '本人確認'
	, TEXT_TOKEN_CONFIRM_2: 'ご入力いただいたメールアドレス'
	, TEXT_TOKEN_CONFIRM_3: '宛てに\n確認メールをお送りしました。'
	, TEXT_TOKEN_CONFIRM_4: 'メールに記載されている\n確認コードを入力してください。'

	, TEXT_PASSRESET_CONFIRM_1: 'パスワードの再設定'
	, TEXT_PASSRESET_CONFIRM_2: 'ご登録されているメールアドレスを入力してください。'
	, TEXT_PASSRESET_CONFIRM_3: 'リセットのためのメールを送信します'
	, TEXT_PASSRESET_BTN: '送信する'
	, TEXT_PASSRESET_CANCEL: '戻る'


	, TEXT_NEW_PASS_CONFIRM_1: 'パスワードの再設定'
	, TEXT_NEW_PASS_CONFIRM_2: '新しいパスワードを入力してください。'
	, TEXT_NEW_PASS_CONFIRM_3: '※半角英数・記号のみ使用できます'
	, ATTR_NEW_PASSWORD: '新しいパスワード'
	, ATTR_NEW_PASSCONFIRM: '新しいパスワード(確認用)'


	, TEXT_REGIST_AGREEMENT_1: '新規登録をすることで'
	, TEXT_REGIST_AGREEMENT_2: 'とクッキーの利用を含む'
	, TEXT_REGIST_AGREEMENT_3: 'にご同意いただいた上、次に進んでください。'

	, TEXT_ADD_PROF_IMG: 'プロフィール画像を設定しよう'

	, TEXT_MEMBER_ENTRANCE_1: 'ライバーの最新情報を執事が提供'
	, TEXT_MEMBER_ENTRANCE_2: '推し活サービスポータル'

	, TEXT_PLAN_DESCRIPTION: '長期間支援すると割引'
	, TEXT_REGISTED_SUPPORTER: 'サポーター登録すると'
	, TEXT_SUPPORTER_W_NAME: '\nさんの'
	, TEXT_SUPPORTER_WELCOME: 'サポーターになりました！'
	, TEXT_SUPPORTER_WELCOME_TEXT: 'サポーター限定のコンテンツをお楽しみください！'

	, TEXT_SUBS_TXT_HEADER: 'さんを応援'
	, TEXT_SUBS_TXT_DEFAULT: 'プレミアムサポーターに登録して'

	, TEXT_SUBS_PLAN_CONTINUE: 'さんのサポート登録が完了していません。\nこちらから再開できます。'

	, TEXT_CHAT_READER_BORDER: '以降未読メッセージ'
	, TEXT_WELCOME: 'おかえりなさいませ。'

	, TEXT_INPUT_HERE: 'ここを入力'
	, TEXT_TXT_END: '末尾'

	, TEXT_SEND_FROM: 'から投稿'
	, TEXT_SELECTED_TO: 'を選択中'
	, TEXT_MESSAGE_HIDDEN: '<メッセージは非表示にされました>'

	, TEXT_OPCHAT_RESERVE: '設定日時に自動でコミュニティチャットに投稿されます'
	, TEXT_OPCHAT_RESERVE_INTRO: '限定画像の公開やライブのお知らせなどに活用しよう!'
	, TEXT_OPCHAT_RESERVE_INTRO_SUB: 'ライブのお知らせや告知などに活用しよう！'



	, TITLE_OPCHAT_RESERVE_LIST: '予約済み投稿一覧'
	, TITLE_OPCHAT_RESERVE_LIST_SORT: '投稿日時順'

	, TEXT_OPCHAT_BLOCK: 'コミュニティチャットの入室制限を行います'

	, LIVER_NOW_SUPPORTER: '現在のサポーター数'
	, LIVER_URLS_BTN_TXT: 'マイページを共有'
	, LIVER_URLS_LABEL_TXT: 'マイページの共有リンク'
	, LIVER_URLS_LABEL_REGIST_INFO: 'サポーターの登録用リンクを貼りたい方はこちら'
	, LIVER_URLS_LABEL_REGIST: 'サポーター登録用ページ'
	, LIVER_URLS_LABEL_FREE_INFO: 'リンクまとめページを貼りたい方はこちら'
	, LIVER_URLS_LABEL_FREE: '埋め込み用リンク'
	, LIVER_URLS_COPY_BTN: 'COPY'
	, LIVER_URLS_COPIED: 'コピーしました！'

	, PRE_CONTENT_PRE_PAGE: '有料ページのプレビュー'
	, PRE_CONTENT_SUPPORTER: 'サポーター一覧・管理'

	, PRE_PAGE_PREVIEW: '有料ユーザー向けページ'
	, FREE_PAGE_PREVIEW: '無料ユーザー向けページ'
	, FREE_PAGE_PREVIEW_TEXT: '用ページ'
	, FREE_PAGE_PREVIEW_ANNOTATION: '※ページによってリンクの表示内容が変わる場合があります'

	, TEMPLATE_JEE_MESSAGE_1: '皆さん、こんにちは。お待ちかねの時間がやってまいりました。\n\nこの特別な画像を皆さんにお見せできることを非常に楽しみにしておりました。\n心の準備はよろしいですか？\n\nそれでは、どうぞ！'

	, ALERT_REGIST_PROFILE: 'ファンの皆様に向けてプロフィールを設定しましょう'
	, ALERT_REGIST_SUBSTXT: 'ファンの皆様に有料サポーターになってもらうために、招待メッセージを書きましょう'
	, ALERT_REGIST_LINKS: 'マイページに表示するリンク集の設定を行いましょう'
	, ALERT_REGIST_PROFIMG: 'プロフィール写真の登録を行いましょう'
	, ALERT_REGIST_WISHLIST: '特別な欲しいものリストを公開するために、準備を行いましょう'


	, TEXT_PLATFORMS: 'ご利用中の配信サービスの<br/>アカウントページURLを登録してください'
	, TEXT_PLATFORMS_INTRO: 'プレゼントの贈り先等の本人確認に必要です'
	, TEXT_PLATFORMS_INTRO_SUB: '登録されていない場合、サポーターからのプレゼントが<br/>お手元にお届けできない場合があります'
	, LABEL_PLATFORMS_URLS: 'アカウントURL'


	, TITLE_WISH_LIST: 'あなたの欲しいものリスト'
}

export const SYSTEM_CONST = {

	SELF_URL: "https://www.hey-jee.com/",

	CAPCHA_API_URL: "https://s.wordpress.com/mshots/v1/",
	G_RECAPCHA_SITE_KEY: "6LfHPukpAAAAADh0MfgfMV8QwYGtPciB2leBXZyr",

	REACT_APP_PUSHER_APP_KEY: "c1f182188b799e8e1754",
	REACT_APP_PUSHER_APP_CLUSTER: "ap3",

	NOIMAGE_PROF_SVG_PATH: "/assets/img/emptyprof.jpg",
	NOIMAGE_PROF_PLUS_SVG_PATH: "/assets/img/emptyprofadd.jpg",
	NOIMAGE_BG_SVG_PATH: "/assets/img/emptheader.jpg",
	NOIMAGE_BG_PLUS_SVG_PATH: "/assets/img/no_image_test.jpeg",
	NOIMAGE_LINK_SVG_PATH: "/assets/img/no_image_icon.jpeg",
	NOIMAGE_LINK_PLUS_SVG_PATH: "/assets/img/addphoto.jpg",
	NOIMAGE_GIFT_IMAGE_SVG_PATH: "/assets/img/gift_noimage.jpg",

	SNACKBAR_SECOND_SUCCESS: 3000,
	SNACKBAR_SECOND_ERROR: 2500,

	TAYORI_AI_KEY_CRE: "9cb0e632-66f5-465f-b50a-0f429f969fae",
	TAYORI_AI_KEY_MEM: "9cdf01a5-732a-4b51-93ef-9d1a6ab37f23",
}

export const API_CONNECTION = {

	URL: 'https://api.hey-jee.com/',
}

if (-1 < window.location.href.indexOf('://dev.hey-jee.com')) {

	API_CONNECTION.URL = 'https://dev.api.hey-jee.com/';
	SYSTEM_CONST.SELF_URL = 'https://dev.hey-jee.com/';
}
if (-1 < window.location.href.indexOf('://localhost')) {

	API_CONNECTION.URL = 'http://localhost:8880/';
	SYSTEM_CONST.SELF_URL = 'http://localhost:3000/';
}
